import DisoVRImg from "../../Image/DisoVR.jpeg";
import CapMeltImg from "../../Image/CapMelt.png";
import EzeGestImg from "../../Image/EzeGest.jpeg";
import RiMaskImg from "../../Image/RiMask.jpeg";
import Sol2GelImg from "../../Image/Sol2Gel.png";
import ZeroCRImg from "../../Image/ZeroCR.jpeg";
import RapiSolImg from  "../../Image/RapiSol.jpeg"

export const TechServicesData=[
    {
        title:"CapMelt",
        description:"CapMelt® has unique ability to formulate, develop and commercialize hot melt extrusion processes and integrate these into Capsule dosage forms. CapMelt® help to accommodate the larger dose in smaller capsule size, thereby making unique proposition.",
        sub_dis:"CapMelt® Enhances solubility/bioavailability for poorly soluble drugs with Continuous processing allows for good process control & scalability. Our unique Solvent - free process is better alternative for multiprocess compression tablets of Amorphous Solid Dispersion.",
        icon:CapMeltImg,
        link:"/ContactUs",
        key:"1"
    },
    {
        title:"ZeroCR",
        description:"Coming Soon...",
        sub_dis:"",
        icon:ZeroCRImg ,
        link:"/ContactUs",
        key:"2"
    },
    {
        title:"DisolVR",
        description:"Our Unique Technology Will help To Enhance The Solubility And Thereby Bioavailability Of Your Poorly Soluble Drug Susbstance. DisolVR® Will Help To ''Reduce The Burden On Healthcare'' With Better Treatment for Patients. DisolVR® will help to extend Product Life Cycle for existing Molecules with reduce dosing and / or better administartion.",
        sub_dis:'DisolVR® Enhances Patient Compliance Whereby Improving Drug Dissolution Rate for BCS II and BCS IV Drug Substance. DisolVR® certainly help in Extending the Product Lifecycle and improving ease of administration. Our Unique Solvent Free Process converting BCS II and BCS IV drugs to Solid Solutions and thereby helping for "Reducing The Burden On Healthcare"',
        icon: DisoVRImg, 
        link:"/ContactUs",
        key:"3"
    },
    {
        title:"EzeGest",
        description:"High Dose Formulations Of Exsisting Drug Products Typically Big In Size And Volume (above 17 mm ). Our EzeGest® Technology Is Reducing The Pill Size To Small Enough Which Can Be Easily Swallowd By Patients. EzeGest® Will Also quite Helpful In Meeting The FDA's Requirments Of Size And Shape And Is Better Alternative For Non - Infringging Formulation Process.",
        sub_dis:"EzeGest® Process Gives More Densy Granulometry For the High Drug Content, Which Gives Better Compressibility. Further EzeGest® With Its Unique Process Will Able To Accommodate High Dose In One Pill Which Can Later Converted Into Score Tablet Which Give Better Dose Titration To Patients. ",

        icon:EzeGestImg , 
        link:"/ContactUs",
        key:"4"
    },
    {
        title:"Sol2Gel",
        description:"Solutions Are Always Prefered Dosage Forms Basis Of There Expected Bioabailability. However Solutions are having low retension time in vivo . Our Sol2Gel® technolgy will convert the solutions to trasnparant Gel structure and thereby improve retension time. Sol2Gel® can be helpful for the Occular drug delivery too, where rentiontime is anticiapted to be more. Thus, with less dosage we offer better Patient Complaince.Sol2Gel® is an unique process of getting liquid output using Melt Extrusion. The Liquid from Downstream Process Is A Clear Solution at Room Temperature.",
        sub_dis:"Sol2Gel® is an unique process of getting liquid output using Melt Extrusion. The Liquid from Downstream Process Is A Clear Solution at Room Temperature.",

        icon:Sol2GelImg , 
        link:"/ContactUs",
        key:"5"
    },
    {
        title:"RiCream",
        description:"Coming Soon...",
        sub_dis:"",

        icon:RiMaskImg , 
        link:"/ContactUs",
        key:"6"
    },
    {
        title:"RapiSol",
        description:"Coming Soon...",
        sub_dis:"",

        icon:RapiSolImg , 
        link:"/ContactUs",
        key:"7"
    }
];