import React from 'react'
import { Link } from 'react-router-dom'
import "./TechServiceCard.css"

const TechServiceCard = ({title1,description1,description2,icon1,link1}) => {
  return (
    <div className='TechSerCard'>
        <div><p><img src={icon1} alt="" className='IconCircle' /></p></div>
        <h1 className='CardTitle'>{title1}</h1>
        <p className='Description'>
        {description1}
        </p>
        <p className='SubDescription'>
        {description2}
        </p>
        <p className="PageLink">
            <Link className="hover-underline-animation" to={link1}>
              Contact Us
            </Link>
        </p>
    </div>
  )
}

export default TechServiceCard