import React from 'react'


const NutraceuticalProducts = () => {
  return (

    <div className="FullSize InnovativeProduct">
    <div className="PipelinePage">
      <div className="InnovativeProductTopBG"></div>
      <div className="Pipeline">
        <h1 className="HHeading">Nutraceutical Products</h1>
      </div>
      <div className="product_List">
        <p className="ShortDiscription">
          {/* We are built on patient-focused innovation and development of drug
          delivery platforms, biosimilars, and prodrugs with applications in a
          wide variety of molecules to improve their clinical utility. */}
        </p>
        {/* <h1 className="HHeading">Innovative Products: </h1> */}
        {/* <div className="ProdDevProgressDetails">{renderBody()}</div> */}
      </div>
    </div>
    {/* <ContactUsCard sender={"Inno"} /> */}
  </div>
  )
}

export default NutraceuticalProducts