import React from 'react'
import InnovativeProdDev from './InnovativeProdDev'
// import NutraceuticalProducts from './NutraceuticalProducts'
import OtherProducts from './OtherProducts'

const ProductDevelopment = () => {
  return (<>
    <InnovativeProdDev/>
    <div className="Border_Bottom"></div>
    {/* <NutraceuticalProducts/>
    <div className="Border_Bottom"></div> */}
    <OtherProducts/>

  </>
  )
}

export default ProductDevelopment