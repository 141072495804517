export const ComplexGenericsItems = [
  
  {
    srno: 3,
    productID: "2003",
    rLDProduct_Dev: "Imdur",
    productName: "Isosorbite Mononitrate Sustained Release Tablets (60 mg)",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Pilot Ongoing",
    // avaliableIn: ["USA", "EU", "Australia", "S.E. Asia", "Other"],
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Austrilia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 4,
    productID: "2004",
    rLDProduct_Dev: "Circardin",
    productName: "Melatonin Prolonong Released Tablets (2 mg)",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Done",
    pivotalBE:"Ongoing",
    technologyTransfer: "Done",
    remark: "Pivotal Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  
  {
    srno: 6,
    productID: "2006",
    rLDProduct_Dev: "Glumetza",
    productName: "Metformin ER Tablets (500 and 1000 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "",
    remark: "EB DONE, Pivotal Ongoing, Under Filing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: false,
      },
    ],
  },
  {
    srno: 7,
    productID: "2007",
    rLDProduct_Dev: "XIGDUO XR",
    productName:
      "Metformin Extended Release + Dapagliflozin Tablets (2.5 +1000, 5 + 500, 5 +1000, 10 + 500, 10 + 1000 mg)",
    productDevStatus: "",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 8,
    productID: "2008",
    rLDProduct_Dev: "Riamet",
    productName: "Artemether + Lumefantine (20+120 mg and 80+480 mg)",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 9,
    productID: "2009",
    rLDProduct_Dev: "Duexis",
    productName: "Ibuprofen and Famotidine Tablets (800+ 26.6 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "",
    remark: "EB DONE, Pivotal Ongoing, Under Filing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 10,
    productID: "2010",
    rLDProduct_Dev: "Adalat GITS",
    productName: "Nefidipine Extended Release Tablets (30mg)",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Materail Awaited",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 11,
    productID: "2011",
    rLDProduct_Dev: "INDERAL LA",
    productName:
      "Propranolol Hydrochloride ER Capsules (60 mg ,80 mg,120 mg and 160 mg",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "6M Stability Done. Ready for Scale up",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 12,
    productID: "2012",
    rLDProduct_Dev: "Slenyto",
    productName:
      "Melatonin Prolonong Released Tablets (1 mg and 5 mg)",
    productDevStatus: "Ongoing",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 13,
    productID: "2013",
    rLDProduct_Dev: "Hemedy",
    productName:
      "Dexamethasone Tablets (20 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Pilot Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 14,
    productID: "2014",
    rLDProduct_Dev: "Famo PFOS (Lupin)",
    productName:
      "Famotidine Powder For Oral Suspension 40mg/ml",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 15,
    productID: "2015",
    rLDProduct_Dev: "Fenoglide",
    productName:
      "Fenofibrate Tablets 40 mg and 120 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 16,
    productID: "2016",
    rLDProduct_Dev: "Intelence",
    productName:
      "Etraverine Tablets 25 mg, 100 mg and 200 mg",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 17,
    productID: "2017",
    rLDProduct_Dev: "Pritin",
    productName:
      "Rifapentine Tablets 200 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
];
