import React from "react";

const FormulDevelopServices = () => {
  return (
    <div className="FullSize FormulDevelopServices">
      <div className="PipelinePage">
        <div className="FormulDevelopServicesTopBG"></div>
        <div className="Pipeline">
          <h1 className="HHeading">Formulation Development Services</h1>
        </div>
        <div className="Services_List">
          <ul className="ShortDiscription">
            <li>Phase I-IV formulation development and reformulations.</li>
            <li>
              Determining blending times for tableting, encapsulation and
              analysis of characteristics (blending time, bulk density, content
              uniformity and flow characteristics).
            </li>
            <li>
              Developing generics and Complex generics, over-the-counter
              products, including tablets (immediate, delayed, modified and
              controlled release), powder for suspension, Multiparticulate
              System including high load pellets formulations, suspensions,
              ointments, using innovative formulation technologies.
            </li>
            <li>Developing line extensions in the above dosage forms.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FormulDevelopServices;
