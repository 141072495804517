export const DifferntiatedProdDevItems = [
  

  {
    srno: 2,
    productID: "3002",
    rLDProduct_Dev: "Lialda",
    productName: "Mesalamine DR Tablets (1.2 gm)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "6 M Stability done. Ready for Scale Up",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 3,
    productID: "3003",
    rLDProduct_Dev: "Pentasa Sachets",
    productName: "Mesalamine Extended Release Pellets (90% and 96%)",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for TT",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 4,
    productID: "3004",
    rLDProduct_Dev: "Pentasa Capsules",
    productName: "Mesalamine Extended Release Capsules (250 and 500 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Done",
    remark: "6 M Stability done. Ready for Scale Up",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 5,
    productID: "3005",
    rLDProduct_Dev: "Procysbi",
    productName:
      "Cysteamine Bitartarate Delayed Release Capsule (25 mg and 75 mg)",
    productDevStatus: "",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 6,
    productID: "3006",
    rLDProduct_Dev: "Zytiga",
    productName: "Abiraterone Acetate Tablets 500 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
];
