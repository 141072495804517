export const GenericsProductItems = [
  // {
  //   srno: 1,
  //   productID: "1001",
  //   rLDProduct_Dev: "Zentac",
  //   productName: "Ranitidine Capsules (150 mg, 300 mg)",
  //   productDevStatus: "Done",
  //   pilotBE: "Done",
  //   scaleUp: "Done",
  //   pivotalBE: "Done",
  //   technologyTransfer: "Done",
  //   remark: "Done",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: false,
  //     },
  //     {
  //       key: "Austrilia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },
  
  {
    srno: 3,
    productID: "1003",
    rLDProduct_Dev: "Lipitor",
    productName: "Atorvastatin Calcium Trihydarte Tablets (10, 20, 40 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for TT",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 7,
    productID: "1007",
    rLDProduct_Dev: "Benicar HCT",
    productName: "Olmesartan + HCTZ Tablets (20 + 12.5, 40 + 12.5, 40 + 25 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Scale Up planned",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: false,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 8,
    productID: "1008",
    rLDProduct_Dev: "Dulcolax DR Tablets",
    productName: "Bisacodyl Enteric Coated Tablets (5 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "Pending",
    remark: "Development Done",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 9,
    productID: "1009",
    rLDProduct_Dev: "Procomp",
    productName: "Prochlorprrazine Maleate Tablets (5 mg and 10 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "",
    technologyTransfer: "TechnologyTransfer",
    remark: "EB Planned",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
];
