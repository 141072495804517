export const OpenPositions = [
  {
    srno: 1,
    positionID: "101",
    department: "Analytical Research & development",
    position: "Junior Scientist",
    job_Requirements: [
      `B.Sc. (Chemistry) / M.Sc. (Chemistry)`,
      ` 1 to 3 years in QC or ARD department`,
    ],
    jobLocation: "Thane",
    technicalRequirements: [
      ` Good Analytical hand`,
      `Aware about operation of the instrument like UV-Spectrophotometer, Dissolution tester, Analytical Weighing Balance, High Performance Liquid Chromatography Instrument, pH meter, Karl Fischer Apparatus etc.`,
      `Experience of work in analytical wet lab like titration, pipetting, weighing etc.`,
      `Have knowledge of Microsoft Excel and Microsoft Word`,
      `Have knowledge about basic requirements during analysis`,
      `Follow current good laboratory practice`,
    ],
    jobDiscription: [
      `To provide calculation sheet and reports of allocated work to reporting manager on time.`,
      `To perform the analytical work on bench as per instruction given by reporting manager.`,
      `To calibrate the allocated instruments on time as per calibration schedule.`,
      `To follow cGLP in the working area.`,
    ],
  },
  {
    srno: 2,
    positionID: "102",
    department: "Analytical Research & development",
    position: "Scientist I / Scientist II",
    job_Requirements: [
      `B.Sc. (Chemistry) / M.Sc. (Chemistry)`,
      `3 to 5 years in QC or ARD department`,
    ],
    jobLocation: "Thane",
    technicalRequirements: [
      `Good Analytical hand`,
      `Good command on handling the instruments like UV-Spectrophotometer, Dissolution tester, Analytical Weighing Balance, High Performance Liquid Chromatography Instrument, Gas Chromatography, pH meter, Karl Fischer Apparatus etc. `,
      `Good commend on documentation part like Data sheet preparation, Method Validation Protocol, Method Validation Report, Method Development Report etc.`,
      `Handling experience for Method Development Work and Method Validation Work.`,
      `Good knowledge about guidelines and requirements for US, EU, ROW market work.`,
    ],
    jobDiscription: [
      `To do the analytical work allocated by reporting manager.`,
      `To operate the critical instrumentation like UV-Spectrophotometer, Dissolution tester, High Performance Liquid Chromatography Instrument, Gas Chromatography etc, as per requirement.`,
      `To perform Method Development Work and Method Validation Work for different formulations.`,
      `To read the different guidelines and literatures for analytical work.`,
      `To maintain the Instruments and equipment available in Analytical laboratory.`,
    ],
  },
  {
    srno: 3,
    positionID: "103",
    department: "Analytical Research & development",
    position: "Associate Senior Scientist/ Senior Scientist / Associate Principal",
    job_Requirements: [
      `B.Sc. (Chemistry) / M.Sc. (Chemistry)`,
      `5 to 12 years in QC or ARD department`,
    ],
    jobLocation: "Thane",
    technicalRequirements: [
      `Good Analytical hand`,
      `Good command on handling the instruments like UV-Spectrophotometer, Dissolution tester, Analytical Weighing Balance, High Performance Liquid Chromatography Instrument, Gas Chromatography, pH meter, Karl Fischer Apparatus etc.`,
      `Good documentation skill for preparation and review of several documents such as Analytical Data sheet, Method Validation Protocol, Method Validation Report, Method Development Report, Risk Assessment Report, Method Equivalency Report, Method Transfer Report etc.`,
      `In depth knowledge of Analytical Method Development work and Method Validation Work.`,
      `Good knowledge about guidelines and requirements for US, EU, ROW market work.`,
      `Effective communication with internal and external customers.`,
      `Good management skill to handle the team members and good coordination skill with team members.`,
    ],
    jobDiscription: [
      `To do the analytical work allocated by reporting manager.`,
      `To lead the team if allocated by the ARD head.`,
      `To operate the critical instrumentation like UV-Spectrophotometer, Dissolution tester, High Performance Liquid Chromatography Instrument, Gas Chromatography etc, as per requirement.`,
      `To perform Method Development Work and Method Validation Work for different formulations.`,
      `To Prepare and review the various documents like Analytical method development report, analytical method validation protocol, method validation report, method transfer report, Risk assessment report etc.`,
      `To take care that the all-Analytical Lab Log book should be updated with compliances.`,
      `To read the different guidelines and literatures for analytical work.`,
      `To maintain the Instruments and equipment available in Analytical laboratory.`,
    ],
  },
  {
    srno: 3,
    positionID: "103",
    department: "Formulation Research Development",
    position: "Associate Senior Scientist/ Senior Scientist / Associate Principal",
    job_Requirements: [
      `M. Pharm/ Ph.D.`,
      `4 - 8 yrs`,
    ],
    jobLocation: "Thane",
    technicalRequirements: [
      `Good Analytical hand`,
      `Good management skill to handle the team members and good coordination skill with team members.`,
    ],
    jobDiscription: [
      `Should have worked for Regulated market product development (ANDA)`,
      `Providing technical expertise/scientific input on formulation development strategies for ANDA / 505(b)2.`,
      `Preparation and practical execution of formulation development Batches using QbD concept.`,
      `Assisting to client during meetings with in a scientific manner.`,
      `Preparation of Technology Transfer documents, Development Reports Documents in eCTD format.`,
      `Monitoring and Execution of Scale up and Exhibit Batches (e.g. process development, implementation of new formulation techniques) and ensuring complex data are interpreted in a scientifically correct way, which can help for development of robust formulation.`,
      `Identifying potential stability and performance issues with prototype formulations and overcome the same.`,
      `Streamlining the activities associated with a wide variety of formulation technologies to shorten Formulation Lab turnaround times and improve the quality of formulations.`,
    ],
  },
];
