import React from "react";
import "./FooterRibhus.css";
// import DunsTradeMark from "./DunsTradeMark.js";
// import Facebook from "../Image/FacebookIcon.png";
// import Twitter from "../Image/TwitterIcon.png";
// import Insta from "../Image/InstaIcon.png";
import LinkedIn from "../Image/LinkedInIcon.png";

export default function FooterRibhus() {
  return (
    <div>
      <footer className="footer">
        {/* <h2 className="Centered">DelNova Healthcare</h2> */}
        <div className="SocialMediaLinks">
          {/* <a
            href="https://www.facebook.com/Delnova-Healthcare-905091739661311/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="facebook Icon"></img>
          </a>
          <a
            href="https://twitter.com/delnova1"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} alt="twitter Icon"></img>
          </a> */}
          <a
            href="https://www.linkedin.com/in/vijayendraredasani/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedIn} alt="LinkedIn Icon"></img>
          </a>
        </div>
        <div className="Social_Media_Accounts">{/* <img></img> */}</div>
        <p>Copyright © 2022 RiBHUS - All Rights Reserved.</p>
      </footer>
    </div>
  );
}
