import React from "react";

const AnalyticalServices = () => {
  return (
    <div className="FullSize AnalyticalServices">
      <div className="PipelinePage">
        <div className="AnalyticalServicesTopBG"></div>
        <div className="Pipeline">
          <h1 className="HHeading">Analytical Services</h1>
        </div>
        <div className="Services_List">
          <p className="ShortDiscription">
            Our analytical and research services evaluate the performance of
            formulations using a whole range of advanced investigative testing
            procedures. They’re an integral part of our work, forming a guiding
            foundation for future developments and innovations.<br></br>
            Our scientists all have Good Manufacturing Practice qualifications
            and expertise of comprehensive testing of materials to make sure
            your products comply with the latest regulatory standards.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticalServices;
