import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactUsForm from "../../Component/Page/ContactUsForm.js";

// import DirectionsIcon from "@mui/icons-material/Directions";
// import RoomIcon from "@mui/icons-material/Room";
import "./ContactUs.css";

const ContactUs = () => {
  // const handleClick = (Text) => {
  //   alert(Text);
  // };

  return (
    <div className="ContactUs">
      <div className="ContactUsBG"></div>
      <div className="ContactUsMain">
        <div className="CompanyDetails">
          <h3>RiBHUS</h3>
          <p>
            An Innovation Center of V<span className="Special_In">i</span>RACS
            Healthcare.
          </p>
          <ul className="ContactDetailsUL">
            <a
              href="https://goo.gl/maps/t6nxpLJjoduVoaam8"
              target="_blank"
              rel="noreferrer"
            >
              <button className="GetDirectionsBTN">
                Get Directions
                {/* <i>
                  <DirectionsIcon />
                </i> */}
              </button>
            </a>
            <li className="Contact Address">
              A220, Road No. 16V, Behind Ashar IT Park, Wagle Industrial Estate,
              Thane (West) - 400 604 (India)
              <br></br>
            </li>
            <a href="tel:986 985 0770">
              <button>Call Us</button>
            </a>
            <li className="Contact Number">+91 89997 16061</li>
            <a
              href="mailto:admin@ribhusinnovations.com"
              target="_blank"
              rel="noreferrer"
            >
              <button>Email Us</button>
            </a>
            <li className="Contact Email">admin@ribhusinnovations.com</li>
            <a
              href="https://www.ribhusinnovation.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button>Visit Us</button>
            </a>
            <li className="Contact Website">www.ribhusinnovation.com</li>
            <a
              href="https://wa.me/919869850770"
              target="_blank"
              rel="noreferrer"
            >
              <button>Chat with Us</button>
            </a>
            <li className="Contact WhatsApp">
              <i>
                <WhatsAppIcon fontSize="large" />
              </i>
            </li>
          </ul>
        </div>
        <ContactUsForm HeadLine={"Drop Us A Line!"} />
      </div>
    </div>
  );
};
export default ContactUs;
//
//
//
//
//
