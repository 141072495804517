import React from "react";
import { Link } from "react-router-dom";
import Research from "../../Image/Research.png";
import PreFormulation from "../../Image/preFormulation.png";
import Technicalsupport from "../../Image/technical-support.png";
import Analytical from "../../Image/analytics.png";
import "../../App.css";
import ComplexGenerics from "./ComplexGenerics";
import GenericsProduct from "./GenericsProduct";
import DifferntiatedProdDev from "./DifferntiatedProdDev";
import InnovativeProdDev from "../../MainModules/ProdDev/InnovativeProdDev";
import "./Pipeline.css";
import CDMOIMG from "../../Image/cdmo_EndToEnd.png";

const Pipeline = () => {
  return (
    <div className="pipeline">
      <div className="PipelineBG"></div>
      <div className="Discription">
        <p>
          {/* DelNova provides flexible services for contract development and
          manufacturing based on Quality by Design principle, including clinical
          trial materials, product development, commercial manufacturing and
          stabilization with Innovative packaging solutions. We help our partner
          to develop and manufacture Differentiated and Innovative formulations
          in oral solids, Injectables, Thin Films technologies, semisolid and
          support as Formulation Partner from beginning to end. Our integrated
          service teams are equipped with experienced, trusted leaders from the
          top pharmaceutical companies. From drug development to scale-up
          through packaging, we are ready to support you with efficiency in
          every part of your drug product development. Our Product Development
          team includes specialists in technology innovation, formulation,
          analytical method development and technical services. We have helped
          dozens of customers like you optimize processes, develop formulations,
          manage their supply chains, and launch their products. */}
          DelNova provides flexible services for contract development and
          manufacturing based on Quality by Design principle, including clinical
          trial materials, product development, commercial manufacturing and
          stabilization with Innovative packaging solutions.
        </p>
      </div>
      <div className="Border_Bottom"></div>
      <div className="CDMO">
        <h1 className="HHeading">
          A Full Service CDMO with End-to-End Capabilities.
        </h1>
        <img src={CDMOIMG} alt="CDMO"></img>
      </div>
      <div className="Border_Bottom"></div>
      <div className="PagesCards">
        <h1 className="HHeading">
          Formulation Development and Manufacturing Services
        </h1>
        <div className="PageCard1">
          <h1 className="PageCardTitle">
            Pre-Formulation <br></br>Services
          </h1>
          <img
            className="PageCardTitleIcon"
            src={PreFormulation}
            alt="About"
          ></img>
          <p className="PageDiscriptionShort">
            DelNova is a fully integrated biopharmaceutical company with
            capabilities to develop and manufacture small and large molecules,
            offering next-generation drug delivery technologies, affordable
            biosimilars, and clinically.
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/PreFormulServices">
              Learn more
            </Link>
          </p>
        </div>

        <div className="PageCard2">
          <h1 className="PageCardTitle">
            Formulation Development <br></br>Services
          </h1>
          <img className="PageCardTitleIcon" src={Research} alt="About"></img>
          <p className="PageDiscriptionShort">
            We are built on patient-focused innovation and development of drug
            delivery platforms, biosimilars, and prodrugs with applications in a
            wide variety of molecules to improve their clinical utility.
          </p>
          <p className="PageLink">
            <Link
              className="hover-underline-animation"
              to="/FormulDevelopServices"
            >
              Learn more
            </Link>
          </p>
        </div>
        <div className="PageCard3">
          <h1 className="PageCardTitle">
            Technical <br></br>Services
          </h1>
          <img
            className="PageCardTitleIcon"
            src={Technicalsupport}
            alt="About"
          ></img>
          <p className="PageDiscriptionShort">
            We identify unmet medical needs in various therapeutic areas and
            develop clinically differentiated products and biosimilars to
            provide effective, safe, and affordable medicines to improve the
            overall quality of life for all patients.
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/TechnicalServices">
              Learn more
            </Link>
          </p>
        </div>
        <div className="PageCard4">
          <h1 className="PageCardTitle">
            Analytical <br></br>Services
          </h1>
          <img className="PageCardTitleIcon" src={Analytical} alt="About"></img>
          <p className="PageDiscriptionShort">
            We identify unmet medical needs in various therapeutic areas and
            develop clinically differentiated products and biosimilars to
            provide effective, safe, and affordable medicines to improve the
            overall quality of life for all patients.
          </p>
          <p className="PageLink">
            <Link
              className="hover-underline-animation"
              to="/AnalyticalServices"
            >
              Learn more
            </Link>
          </p>
        </div>
      </div>

      <DifferntiatedProdDev />
      <InnovativeProdDev />
      <ComplexGenerics />
      <GenericsProduct />
      {/* <div className="PipelinePage">
        <div className="Pipeline">
          <h1 className="HHeading">Pipeline</h1>
        </div>
        <div className="product_List">
          <div className="ProdDevProgressDetails">{renderBody()}</div>
        </div>
      </div> */}
    </div>
  );
};
export default Pipeline;
