import React from "react";
import CompanyOverview from "./CompanyOverview";
import Deliver from "../../Image/QualityInDesignIMG.png";
import Develop from "../../Image/peopleOriented.png";
// import Innovate from "../../Image/Innovate.jpeg";
import Innovate from "../../Image/GenderequalityDel.png";

import CoreTeam from "./CoreTeam.js";
import Mission from "./Mission.js";
import "../../App.css";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <div className="CompanyOverview">
        <CompanyOverview />
      </div>
      <div className="WeBeliveIn">
        <h4 className="AboutUs_WeBeliveIn">WE BELIEVE IN</h4>
        <ul className="iconList">
          <li>
            <img src={Innovate} alt="Innovate"></img>
            <span>Gender Equality</span>
          <p>
              We Do Incremental Innovation To Develop Differentiated Formulation
              Which Impact Peoples Lives.
            </p>
          </li>

          <li>
          <img src={Develop} alt="Develop"></img>
          <span>People Oriented</span>
          <p>
              We Develop Technologies and Process for Pharmaceutical and
              Nutraceutical Formulations.
            </p>
          </li>

          <li>
            <img src={Deliver} alt="Deliver"></img>
            <span>
              Quality <span className="Special_wordIn">iN</span> Design
            </span>
            {/* <p>
              We Deliver Innovative And Differentiated Formulations with Client
              Satisfaction.
            </p> */}
          </li>
        </ul>
      </div>
      <div className="Border_Bottom"></div>

      <Mission />
      <CoreTeam />
    </div>
  );
};
export default AboutUs;
