import React from "react";

const TechnicalServices = () => {
  return (
    <div className="FullSize TechnicalServices">
      <div className="PipelinePage">
        <div className="TechnicalServicesTopBG"></div>
        <div className="Pipeline">
          <h1 className="HHeading">Technical Services</h1>
        </div>
        <div className="Services_List">
          <ul className="ShortDiscription">
            <li>Troubleshooting commercial products.</li>
            <li>
              Formulation optimization and evaluation of new raw materials in
              existing products.
            </li>
            <li>
              Technical transfer of products from R&D to production facilities.
            </li>
            <li>
              Process optimization and scale-up and assessment of current
              products.
            </li>
            <li>
              Modernizing existing manufacturing and clinical supplies processes
              using time and cost saving approaches like Direct Compression,
              Twin screw granulations and many such in line with continuous
              manufacturing approach.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TechnicalServices;
