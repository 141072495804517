import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import RiBHUSLOGO from "../Image/RiBHUS12.png";
import {
  ProdDevMenuItems,
  TechPlatMenuItems,
  // AboutUsMenuItems,
} from "./MenuItems";
import NavSubDropdown from "./NavSubDropdown";

function Navbar() {
  const [click, setClick] = useState(false);
  const [menuSelected, setMenuSelected] = useState();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const SetMenuItem = (MenuItem) => {
    setMenuSelected(MenuItem);
  };
  return (
    <div>
      <nav className="navbar">
        <Link
          to="/"
          className="navbar-logo"
          onClick={(closeMobileMenu, () => SetMenuItem("Home"))}
        >
          <img src={RiBHUSLOGO} alt="DelNova logo"></img>
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/"
              className={
                menuSelected === "Home" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => setMenuSelected("Home"))}
              refresh="true"
            >
              HOME
            </Link>
          </li>
          <li
            className="nav-item"
            onMouseEnter={() => setMenuSelected("ProdDev")}
            onMouseLeave={() => setMenuSelected("")}
            onClick={() => setClick(false)}
          >
            <Link
              to="/Proddev"
              className={
                menuSelected === "ProdDev" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => setMenuSelected("ProdDev"))}
              refresh="true"
            >
              PRODUCT DEVELOPMENT
            </Link>
            {menuSelected === "ProdDev" && (
              <NavSubDropdown List={ProdDevMenuItems} />
            )}
          </li>
          <li
            className="nav-item"
            onMouseEnter={() => setMenuSelected("TechPlat")}
            onMouseLeave={() => setMenuSelected("")}
            onClick={() => setClick(false)}
          >
            <Link
              to="/Techplat"
              className={
                menuSelected === "TechPlat" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => setMenuSelected("TechPlat"))}
            >
              OUR TECHNOLOGIES
            </Link>
            {/* {menuSelected === "TechPlat" && (
              <NavSubDropdown List={TechPlatMenuItems} />
            )} */}
          </li>
          <li
            className="nav-item"
            // onMouseEnter={()=>setMenuSelected("AboutUs")}
            // onMouseLeave={()=>setMenuSelected("")}
            onClick={() => setClick(false)}
          >
            <Link
              to="/AboutUs"
              className={
                menuSelected === "AboutUs" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("AboutUs"))}
            >
              ABOUT
            </Link>
            {/* {menuSelected === "AboutUs" && <NavSubDropdown List={AboutUsMenuItems} />} */}
          </li>
          <li className="nav-item" onClick={() => setClick(false)}>
            <Link
              to="/ContactUs"
              className={
                menuSelected === "ContactUs" ? "Active nav-links" : "nav-links"
              }
              onClick={(closeMobileMenu, () => SetMenuItem("ContactUs"))}
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Navbar;
