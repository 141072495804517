export const InnovativeProdDevItems = [

  {
    srno: 1,
    productID: "4001",
    rLDProduct_Dev: "DissolVR",
    productName: "R0701 - (Melatonin Oral Solution (2 mg / ml))",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "Done",
    poc: "",
    licensing: "",
    remark: "EP 4021411A4/AU2020339867A1",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 2,
    productID: "4002",
    rLDProduct_Dev: "DissolVR",
    productName: "R0702 - (Melatonin Oral Solution (3 mg / ml))",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "Done",
    poc: "",
    licensing: "Pending",
    remark: "Development done. Patented Composition",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 3,
    productID: "4003",
    rLDProduct_Dev: "DissolVR",
    productName: "R0703 - (Melatonin Injection)",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 4,
    productID: "4004",
    rLDProduct_Dev: "DissolVR",
    productName: "R0704 - (Nimodipine Oral Solution 10 mg / ml)",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Alcohol free stable composition.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 5,
    productID: "4005",
    rLDProduct_Dev: "DissolVR",
    productName: "R0705 - (Nimodipine Concentrate Injection)",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Alcohol free stable composition for IV administration",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 6,
    productID: "4006",
    rLDProduct_Dev: "DissolVR",
    productName: "R0706 - (Potassium-competitive acid blocker)",
    productDevStatus: "",
    ipStatus: "",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 7,
    productID: "4007",
    rLDProduct_Dev: "Ezegest",
    productName: "R0401 - (Metformin Powder for Solution 1 gm (High Dose))",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 8,
    productID: "4008",
    rLDProduct_Dev: "Ezegest",
    productName: "R0402 - (Dexamethasone Tablets 40 mg) High Dose",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "Done",
    poc: "Done",
    licensing: "Pending",
    remark: "High Dose Composition.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 9,
    productID: "4009",
    rLDProduct_Dev: "Ezegest",
    productName: "R0403 - (Etraverine Powder For Oral Suspension (10 mg / ml))",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "Done",
    poc: "Done",
    licensing: "Pending",
    remark: "Solvent-free, supra bio-available",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 10,
    productID: "4010",
    rLDProduct_Dev: "Ezegest",
    productName: "R0404 - (Abirateron Acetate Powder For Solution)",
    productDevStatus: "",
    ipStatus: "",
    labStability: "Pending",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 11,
    productID: "4011",
    rLDProduct_Dev: "Ezegest",
    productName: "R0405 - (High Dose Abiraterone Acetate Tablets)",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "High Dose, single unit composition.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  // {
  //   srno: 12,
  //   productID: "4012",
  //   rLDProduct_Dev: "Ezegest",
  //   productName: "R0406",
  //   productDevStatus: "",
  //   ipStatus: "",
  //   labStability: "Done",
  //   poc: "Done",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  {
    srno: 13,
    productID: "4013",
    rLDProduct_Dev: "P2C",
    productName: "R0801 - (Luliconazole 1% w/w Cream)",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Powder 2 cream formulation.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 14,
    productID: "4014",
    rLDProduct_Dev: "P2C",
    productName: "R0802 - (Tofacitinib Ointment)",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Novel powder 2 cream formulation.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 15,
    productID: "4015",
    rLDProduct_Dev: "Capmelt",
    productName: "R0501 - (Janus kinase (JAK) inhibitor) for Ulcerative Colitis",
    productDevStatus: "",
    ipStatus: "",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 16,
    productID: "4016",
    rLDProduct_Dev: "Rapisol",
    productName: "R0201 - (Tofacitinib Oral Film)",
    productDevStatus: "",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Fast disolving aqueous ODF",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 17,
    productID: "4017",
    rLDProduct_Dev: "Rapisol",
    productName: "R0202 - (Prochlorprrazine Maleate Oral Dissolving Film (5 mg and 10 mg))",
    productDevStatus: "",
    ipStatus: "",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Fast disolving aqueous ODF",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 18,
    productID: "4018",
    rLDProduct_Dev: "Rapisol",
    productName: "R0203 - (Ramelteon Sublingual Film 8 mg / Oral Film)",
    productDevStatus: "",
    ipStatus: "",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 19,
    productID: "4019",
    rLDProduct_Dev: "Rapisol",
    productName: "R0204 - (Melatonin Oral Dissolving Film)",
    productDevStatus: "",
    ipStatus: "",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Development done. Stability ongoing.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 20,
    productID: "4020",
    rLDProduct_Dev: "ZeroCR",
    productName: "R0101 - (Methylphenedate ER Suspension)",
    productDevStatus: "",
    ipStatus: "Done",
    labStability: "",
    poc: "",
    licensing: "Pending",
    remark: "Development done. No Ion Exchange Resin.",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },


//Old product list disabled on 03-Oct-2024

  // {
  //   srno: 3,
  //   productID: "RIB03033",
  //   rLDProduct_Dev: "DisolVR",
  //   productName: "RIB03033",
  //   productDevStatus: "Done",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },
  // {
  //   srno: 4,
  //   productID: "RIB04066",
  //   rLDProduct_Dev: "EzeGest",
  //   productName: "RIB04066",
  //   productDevStatus: "Done",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 5,
  //   productID: "RIB07073",
  //   rLDProduct_Dev: "RapiSol",
  //   productName: "RIB07073",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 6,
  //   productID: "RIB03061",
  //   rLDProduct_Dev: "DisolVR",
  //   productName: "RIB03061",
  //   productDevStatus: "Done",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 7,
  //   productID: "RIB07048",
  //   rLDProduct_Dev: "RapiSol",
  //   productName: "RIB07048",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 8,
  //   productID: "RIB07007",
  //   rLDProduct_Dev: "RapiSol",
  //   productName: "RIB07007",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 9,
  //   productID: "RIB07003",
  //   rLDProduct_Dev: "RapiSol",
  //   productName: "RIB07003",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 10,
  //   productID: "RIB07056",
  //   rLDProduct_Dev: "RapiSol",
  //   productName: "RIB07056",
  //   productDevStatus: "Done",
  //   ipStatus: "Done",
  //   labStability: "Done",
  //   poc: "Done",
  //   licensing: "",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 11,
  //   productID: "RIB04049",
  //   rLDProduct_Dev: "EzeGest",
  //   productName: "RIB04049",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 12,
  //   productID: "RIB03049",
  //   rLDProduct_Dev: "DisolVR",
  //   productName: "RIB03049",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 13,
  //   productID: "RIB04009",
  //   rLDProduct_Dev: "EzeGest",
  //   productName: "RIB04009",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 14,
  //   productID: "RIB05050",
  //   rLDProduct_Dev: "Sol2Gel",
  //   productName: "RIB05050",
  //   productDevStatus: "Pending",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 15,
  //   productID: "RIB04033",
  //   rLDProduct_Dev: "EzeGest",
  //   productName: "RIB04033",
  //   productDevStatus: "Done",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Done",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 16,
  //   productID: "RIB06010",
  //   rLDProduct_Dev: "RiCream",
  //   productName: "RIB06010",
  //   productDevStatus: "Done",
  //   ipStatus: "Done",
  //   labStability: "",
  //   poc: "Done",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  // {
  //   srno: 17,
  //   productID: "RIB01068",
  //   rLDProduct_Dev: "CapMelt",
  //   productName: "RIB01068",
  //   productDevStatus: "Pending",
  //   ipStatus: "Pending",
  //   labStability: "Pending",
  //   poc: "Pending",
  //   licensing: "Pending",
  //   remark: "Development ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },
];
