import React from "react";
import "../../App.css";

const PreFormulServices = () => {
  return (
    <div className="FullSize PreFormulServices">
      <div className="PipelinePage">
        <div className="PreFormulServicesTopBG"></div>
        <div className="Pipeline">
          <h1 className="HHeading">Pre-Formulation Services</h1>
        </div>
        <div className="Services_List">
          <ul className="ShortDiscription">
            <li>
              Chemical compatibility and reactivity to packaging substrates.
            </li>
            <li>Physical properties and product characterization.</li>
            <li>Accelerated degradation.</li>
            <li>(API)-excipient interaction studies.</li>
            <li>Hygroscopicity studies.</li>
            <li>Photostability of API.</li>
            <li>Prototype evaluations.</li>
            <li>Reverse engineering.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PreFormulServices;
