import React from "react";
import { Link } from "react-router-dom";
// import { Link as Rlink } from "react-scroll";

import Quality from "../../Image/medical.png";
import Reserch from "../../Image/pharmaceutical (2).png";
import AboutIcon from "../../Image/about.png";

import "../../App.css";
import "./Home.css";

export default function Home() {
  return (
    <div className="FullSize home">
      <div className="Main heading">
        <div className="mainTitle">
          <div className="Primery Heading">
            <p className="Line1">
              WE OFFER 
              <span className="Special_wordDiff"> DIFFERENTIATED</span> AND
              <span className="Special_wordInno"> INNOVATIVE </span> <br></br>
              HEALTHCARE SOLUTIONS
            </p>
          </div>
        </div>
      </div>
      <div className="Discription">
        <p>
          Product Development Partner for the ‘Novel Drug Delivery Systems’
          <br></br> Our Differentiated | Innovative Technology and Processes,<br></br>
          will help our Partners to extend Product Lifecycle and comeup with cost effective development. Our Platform Technologies will Patented, Simple and Commercially Viabale with Patient Centric approch.
        </p>
      </div>

      <div className="Border_Bottom"></div>

      <div className="PagesCards">
        <div className="PageCard1">
          <h1 className="PageCardTitle">
            <p>About Organization</p>
          </h1>
          <img className="PageCardTitleIcon" src={AboutIcon} alt="About"></img>
          <p className="PageDiscriptionShort">
          RiBHUS is a fully integrated healthcare company with capabilities to develop and manufacture small and large molecules, offering next-generation drug delivery technologies, affordable healthcare solutions, and clinically differentiated specialty pharmaceutical products with Patient Centric Approach.
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/AboutUs">
              Learn more
            </Link>
          </p>
        </div>

        <div className="PageCard2">
          <h1 className="PageCardTitle">
            <p> Research And Development</p>
          </h1>
          <img className="PageCardTitleIcon" src={Reserch} alt="About"></img>
          <p className="PageDiscriptionShort">
            Our dedicated and open-minded team work on incremental innovation
            for development of drug delivery platforms,niche products and
            applications, on a wide variety of molecules to improve their
            stability, effectiveness and clinical utility. We help our Partner
            on redefining the product portfolio with cost effective and timely
            services.
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/innovativeproducts">
              Learn more
            </Link>
          </p>
        </div>
        <div className="PageCard3">
          <h1 className="PageCardTitle">
            <p>
              {" "}
              Quality <span className="Special_In">iN</span> Design
            </p>
          </h1>
          <img className="PageCardTitleIcon" src={Quality} alt="About"></img>
          <p className="PageDiscriptionShort">
            We build <span className="Special_In">iN</span> quality. With our
            highly experienced and dedicated technical team, we laid the
            foundation of the product development. This will help our partner to
            have efficient, stable formulation and scalable process and to
            patients, we help to get cost effective healthcare solutions. Thus,
            our selection of process, composition and approach will give quality
            formulation.
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/AboutUs">
              Learn more
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
