export const ProdDevMenuItems = [
  // {
  //   title: "Capabilities",
  //   path: "/capabilities",
  //   sec: "#companyOverview",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "Research And Development",
  //   path: "/resanddev",
  //   sec: "444",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "Specialized Services",
  //   path: "/specializedservices",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "Aanlytical Services",
  //   path: "/analyticalservices",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "Formulation Development",
  //   path: "/formulationdev",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
  {
      title: "Innovative Products",
      path: "innovativeproducts",
      sec: "800",
      cName: "dropdown-link",
    },
    {
      title: "Nutraceutical Products",
      path: "/nutraceuticalproducts",
      sec: "800",
      cName: "dropdown-link",
    },
    {
        title: "Other Products",
        path: "/otherproducts",
        sec: "800",
        cName: "dropdown-link",
      },

];
export const TechPlatMenuItems = [
  // {
  //   title: "CapMelt",
  //   path: "/capmelt",
  //   sec: "#companyOverview",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "ZeroCR",
  //   path: "/zerocr",
  //   sec: "444",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "DisolVR",
  //   path: "/disolvr",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "EzeGest",
  //   path: "/ezegest",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "Sol2Gel",
  //   path: "/sol2gel",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "RiCream",
  //   path: "/ricream",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
  // {
  //   title: "RapiSol",
  //   path: "/rapis zol",
  //   sec: "800",
  //   cName: "dropdown-link",
  // },
];
export const AboutUsMenuItems = [
  {
    title: "Our Legacy",
    path: "/AboutUs",
    sec: "#companyOverview",
    cName: "dropdown-link",
  },
  {
    title: "Our Leadership",
    path: "/AboutUs",
    sec: "444",
    cName: "dropdown-link",
  },
  {
    title: "Our People",
    path: "/AboutUs",
    sec: "800",
    cName: "dropdown-link",
  },
];
