import React, { useState } from "react";
import "./NavSubDropdown.css";
import { Link } from "react-router-dom";
function NavSubDropdown(props) {
  const [click, setClick] = useState();
  const handleClick = () => setClick(!click);
  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {props.List.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                scroll={item.sec}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
export default NavSubDropdown;
