import React from "react";
import "../../App.css";
import "./CompanyOverview.css";

const CompanyOverview = () => {
  return (
    <div className="FullSize companyOverview">
      <div className="companyOverviewPage">
        {/* <h1 className="HHeading">Company Overview</h1> */}
        <div className="WhoWeAre">
          <h4>WE ARE DIFFERENT</h4>
          <p>
            We are a young privately held research driven, innovative
            pharmaceutical company focused on&nbsp;
            <span className="newFontTagline">
              “Reducing the burden of Healthcare”,
            </span>
            &nbsp;through alliance in development and manufacturing. We aim to
            reduce healthcare cost by providing solution for lifecycle extension and to ever challenging
            problems faced by generic and innovator / branded companies. We Help
            our partners to develop and establish new platform technologies with
            seamless transfer from existing technologies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;
