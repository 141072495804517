import React from "react";
// import FemaleICON from "../../Image/FemaleIconn.png";
import MaleICON from "../../Image/VijayendrakumarRedasani.jpeg";
// import PersonICON from "../../Image/PersonIcon.png";
import PersonICON from "../../Image/SR.jpeg";
import APImage from "../../Image/AP.jpeg";
import ASImage from "../../Image/AS.jpeg";
import PKImage from "../../Image/PK.jpeg";
import PAImage from "../../Image/PA.jpeg";
import SKImage from "../../Image/SK.jpeg";


import "../../App.css";
import "./CoreTeam.css";

const CoreTeam = () => {
  return (
    <div className="leadershipTeam">
      <div className="leadershipTeamPage">
        <h1 className="HHeading">OUR TEAM</h1>
        <div className="TeamMain">
          <div className="TeamMainPersonDetails" id="card1">
            <div className="FrontSideCard">
              <img src={MaleICON} className="TeamMainPersonIMG" alt=""></img>
              <p className="TeamMainPersonNAME">Vijayendrakumar Redasani </p>
              <p className="TeamMainPersonOISITION">Founder & CEO</p>
            </div>
            <div className="BackSideCard">
              <p className="TeamMainPersonNAME">Vijayendrakumar Redasani </p>
              <p className="SpecialWords">
                {/* A paragraph is a series of related sentences developing a
                central idea, called the topic. Try to think about paragraphs in
                terms of thematic unity: a paragraph is a sentence or a group of
                sentences that supports one central, unified idea. Paragraphs
                add one idea at a time to your broader argument. */}
                Pharmaceutical professional with extensive experience in the
                generic formulation development. Demonstrated supervisory and
                technical leadership in various ANDA projects. Provides
                leadership and strategic input for the R&D activities associated
                with allocated projects. Good understanding & hands on with
                almost all the manufacturing requirements associated with solid
                dosage form manufacturing. Highly motivated, self-driven and
                result-oriented work philosophy with solid technical,
                interpersonal and leadership skills to achieve key business
                milestones & objectives.
              </p>
            </div>
          </div>
          
          <div className="TeamMainPersonDetails" id="card3">
            <div className="FrontSideCard">
              <img src={APImage} className="TeamMainPersonIMG" alt=""></img>
              <p className="TeamMainPersonNAME">Amar Patel</p>
              <p className="TeamMainPersonOISITION">Head - Business Development</p>
            </div>
            <div className="BackSideCard">
              <p className="TeamMainPersonNAME">Amar Patel</p>
              <p className="SpecialWords">
              Doctorate in Chemistry, with 12+ years of experiance in Formulation development. 
              Skilled troubleshooter with strong organizational, communication skills, 
              and leadership qualities. Strong expertise in scientific writing 
              and review of technical report. Worked with companies like Bristol Myers Squibb, 
              Cambrex. 
              </p>
            </div>
          </div>


          <div className="TeamMainPersonDetails" id="card2">
            <div className="FrontSideCard">
              <img src={PersonICON} className="TeamMainPersonIMG" alt=""></img>
              <p className="TeamMainPersonNAME">Shweta Redasani</p>
              <p className="TeamMainPersonOISITION">Co-Founder</p>
            </div>
            <div className="BackSideCard">
              <p className="TeamMainPersonNAME">Shweta Redasani</p>
              <p className="SpecialWords">
              Science graduate professional with expertise in negotiation 
              and getting connected with people. Self-driven and result-oriented with 
              solid technical, interpersonal and leadership skills to achieve key business 
              milestones & objectives. Ability to develop and execute short, mid, and 
              long term strategies in support of business needs. Maintains the relationships, 
              engages and aligns with the global delivery and support teams.
              </p>
            </div>
          </div>
        </div>

          {/* <div className="leadershipTeam"> */}
      <div className="leadershipTeamPage">
        {/* <h1 className="HHeading"></h1> */}
        <div className="TeamMain">
        <div className="TeamMainPersonDetails" id="card4">
            <div className="FrontSideCard">
              <img src={SKImage} className="TeamMainPersonIMG" alt=""></img>
              <p className="TeamMainPersonNAME">Shantilal Khabiya</p>
              <p className="TeamMainPersonOISITION">Head - Operation</p>
            </div>
            <div className="BackSideCard">
              <p className="TeamMainPersonNAME">Shantilal Khabiya</p>
              <p className="SpecialWords">
              Pharmaceutical professional with Master in Finance administration, has with extensive experience 
              in the technocommecial and formulation development. Highly motivated, self-driven asolid technical, 
              interpersonal and leadership skills, as project manager. Extensive experiance in working like BASf, Novartis, 
              Piramals, Famycare and good going human being. 
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
        </div>
    {/* <div className="leadershipTeam"> */}
      <div className="leadershipTeamPage">
        <h1 className="HHeading">ADVISORY BOARD</h1>
        <div className="TeamMain">
        <div className="TeamMainPersonDetails" id="card4">
            <div className="FrontSideCard">
              <img src={ASImage} className="TeamMainPersonIMG" alt=""></img>
              <p className="TeamMainPersonNAME">Ashok Sarkar</p>
              <p className="TeamMainPersonOISITION">Chief Advisor - Business Development</p>
            </div>
            <div className="BackSideCard">
              <p className="TeamMainPersonNAME">Ashok Sarkar</p>
              <p className="SpecialWords">
              Update In Progress...
              </p>
            </div>
          </div>
          <div className="TeamMainPersonDetails" id="card5">
            <div className="FrontSideCard">
              <img src={PKImage} className="TeamMainPersonIMG" alt=""></img>
              <p className="TeamMainPersonNAME">Prashant Kharkar</p>
              <p className="TeamMainPersonOISITION">Chief Advisor - NCE Development</p>
            </div>
            <div className="BackSideCard">
              <p className="TeamMainPersonNAME">Prashant Kharkar</p>
              <p className="SpecialWords">
              Prashant S. Kharkar, PhD, received his B. Pharm. degree in 1998. He obtained his M. Pharm. Sci. 
              (2000) and Ph.D. (Tech.) (2004), both in Pharmaceutical Chemistry, from Institute of Chemical 
              Technology (ICT), Mumbai, India. Dr. Kharkar completed his postdoctoral research (2006-2009) on 
              neurodegenerative diseases and disorders with Prof. Aloke Dutta at Wayne State University, Detroit, 
              MI. Previously, Dr. Kharkar worked in Indian and Foreign Multinational Drug Discovery companies 
              such as Piramal Life Sciences, Reliance Life Sciences, and Nycomed Pharma Pvt. Ltd. Currently he is 
            working as a Professor of Medicinal Chemistry at ICT. 
              </p>
            </div>
          </div>
          <div className="TeamMainPersonDetails" id="card6">
            <div className="FrontSideCard">
              <img src={PAImage} className="TeamMainPersonIMG" alt=""></img>
              <p className="TeamMainPersonNAME">Purnima Amin</p>
              <p className="TeamMainPersonOISITION">Chief Advisor - Novel Drug Delivery System</p>
            </div>
            <div className="BackSideCard">
              <p className="TeamMainPersonNAME">Purnima Amin</p>
              <p className="SpecialWords">
              Last 32 years working as researcher, Dr. Amin is instrumental in giving lot of budding scientist to profession. 
              Her major focus for research is in Hot Melt Extrusion (HME) technology, where to develop innovative drug delivery systems 
              for applications ranging from Oral to Topical to Parenteral routes of drug delivery. She has published more than 60 peer-reviewed manuscripts 
              in the field of HME. As Chief advisor for Novel Drug Delivery System group, of RiBHUS Dr.Amin will be guiding Formulation team with her rich experience and expertise for Reducing Burden of Healthcare. 
              </p>
            </div>
            </div>
        </div>

      </div>
    {/* </div> */}
       
      </div>
    </div>
  );
};

export default CoreTeam;
