import React from 'react'
import TechServiceCard from './TechServiceCard'
import {TechServicesData} from "./TechServicesData"

const TechnologyPlatforms = () => {
  return (
    
    
    <div  className='Card_ContainerTechPlat'>

<div className="FullSize InnovativeProduct">
      <div className="PipelinePage">
        <div className="InnovativeProductTopBG"></div>
        <div className="Pipeline">
          <h1 className="HHeading">Our Technologies</h1>
        </div>
        <div className="product_List">
          <p className="ShortDiscription">
          The percentage of drug candidates with relatively poor biopharmaceutical properties, and particularly candidates with poor drug release properties, has increased significantly over last decade. These substances require considerable formulation effort to achieve their dosage forms with either Improved Solubility / Bioavailability and also way of its administration which must be acceptable by regulatory agencies globally and also offering Patient Compliance. At RiBHUS We Offer "Innovation For Better Healtcare"
          </p>
        </div>
      </div>
      {/* <ContactUsCard sender={"Inno"} /> */}
    </div>
     
      {TechServicesData.map(({title,description,icon,sub_dis,link,key})=>{
        return <div key={key}>
          <TechServiceCard title1={title} description1={description} description2={sub_dis} icon1={icon} link1={link} />
        </div>
      })

      }
    </div>
    
  )
}

export default TechnologyPlatforms