import React from "react";
import Delnova from "../../Image/DelnovaLogoF2.png";

const OtherProducts = () => {
  return (
    <div className="home otherProduct">
      <div className="LinkToDelNova">
        <h1 className="RedirectMSG">
          For More Product details please visit our Affiliated partner
        </h1>

        <a
          href="https://delnovahealth.com/ResearchAndDevlop"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Delnova} alt="delnova redirect"></img>
        </a>
      </div>
      <div className="Border_Bottom"></div>

      <div className="Discription">
        <p>
          ‘Product Development Partner’ for the ‘Generics and Complex Generics
          Drug Delivery Systems’<br></br> Our Differentiated | Innovative
          Technology and Processes,<br></br>
          will help to meet the time-lines and cost thereby achieving the
          mission&nbsp;
          <span className="newFontTagline">
            “Reducing the Burden of Healthcare”.
          </span>
        </p>
      </div>
      <div className="Border_Bottom"></div>
    </div>
  );
};

export default OtherProducts;
