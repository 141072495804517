import React from "react";
import { Link } from "react-router-dom";
import DiffIcon from "../../Image/pharmaceutical.png";
import InnoIcon from "../../Image/innovation.png";
import CompIcon from "../../Image/medicine.png";
import GenrIcon from "../../Image/pills (1).png";

import "../../App.css";
import "./ResearchAndDevlop.css";
const ResearchAndDevlop = () => {
  return (
    <div className="FullSize researchAndDevelop">
      <div className="ResearchAndDevelopPage">
        <div className="ResearchAndDevelopBG">
          <h1 className="HHeading">
            Research And Development for <br></br>Differentiated And Innovative
            Healthcare Solutions
          </h1>
        </div>
        <div className="Discription">
          <p>
            Our Differentiated | Innovative Technology and Processes,<br></br>
            will help to meet the time-lines and cost thereby achieving the
            mission&nbsp;
            <span className="newFontTagline">
              “Reducing the Burden of Healthcare”.
            </span>
          </p>
        </div>
        <div className="Border_Bottom"></div>
        <div className="PagesCards">
          <div className="PageCard4">
            <h1 className="PageCardTitle">Generics Formulation</h1>
            <img className="PageCardTitleIcon" src={GenrIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
              At DelNova we have a team of experienced product development
              scientists, who work hand in hand, with analytical and other cross
              functional teams to work on expedite timelines for Generic product
              development. We believe in Right at First time and help our
              partners to meet timelines with cost effective formulations.
            </p>
            <p className="PageLink">
              <Link className="hover-underline-animation" to="/GenericsProduct">
                Learn more
              </Link>
            </p>
          </div>
          <div className="PageCard3">
            <h1 className="PageCardTitle">Complex Generics Formulation</h1>
            <img className="PageCardTitleIcon" src={CompIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
              Our innovative approaches and openness in thinking and listening,
              will help us to make the most of from timelines. Our Quality iN
              Design will help to get best of complex generics at first go. Our
              supply chain team will help us to get the raw material promptly
              and thereby delivery of prototype on time. Thus, we help our
              partner to keep abreast in competition.
            </p>
            <p className="PageLink">
              <Link className="hover-underline-animation" to="/ComplexGenerics">
                Learn more
              </Link>
            </p>
          </div>
          <div className="PageCard1">
            <h1 className="PageCardTitle">Differentiated Formulation</h1>
            <img className="PageCardTitleIcon" src={DiffIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
              Simple yet unique process adoption and technology platform gives
              us an idea to create our on IPs. We explore each drug substance,
              we touch, with aim to make something new from it, for our
              patients, which is safe, effective and economical.
            </p>
            <p className="PageLink">
              <Link
                className="hover-underline-animation"
                to="/DifferntiatedProdDev"
              >
                Learn more
              </Link>
            </p>
          </div>

          <div className="PageCard2">
            <h1 className="PageCardTitle">Innovative Formulation</h1>
            <img className="PageCardTitleIcon" src={InnoIcon} alt="About"></img>
            <p className="PageDiscriptionShort">
              We believe - An Idea can change life. Our collaboration with
              academic institutes, budding scientists and renowned acadamicians,
              across the globe is helping us to get the best of thought. We
              encourage the thoughts and give the platform to such scientists
              and start up companies, so the Patients can benefit with
              innovative product miracles.
            </p>
            <p className="PageLink">
              <Link
                className="hover-underline-animation"
                to="/InnovativeProdDev"
              >
                Learn more
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResearchAndDevlop;
