import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { createBrowserHistory } from "history";
import Navbar from "./Layout/Navbar";
import ScrollToTop from "./Layout/ScrollToTop";
import Home from "./MainModules/Home/Home.js";
import FooterRibhus from "./Layout/FooterRibhus";
import "./App.css";
import AboutUs from "./MainModules/About/AboutUs";
import ApplyHere from "./Component/Page/ApplyHere.js";

import ResearchAndDevlop from "./Component/Page/ResearchAndDevlop.js";
import ShareYourIdea from "./Component/Page/ShareYourIdea.js";
import DifferntiatedProdDev from "./Component/Page/DifferntiatedProdDev.js";
import InnovativeProdDev from "./MainModules/ProdDev/InnovativeProdDev";
import ComplexGenerics from "./Component/Page/ComplexGenerics.js";
import GenericsProduct from "./Component/Page/GenericsProduct.js";
import OurPresence from "./Component/Page/OurPresence.js";
import Pipeline from "./Component/Page/Pipeline.js";
import PreFormulServices from "./Component/Page/PreFormulServices.js";
import FormulDevelopServices from "./Component/Page/FormulDevelopServices.js";
import TechnicalServices from "./Component/Page/TechnicalServices.js";
import AnalyticalServices from "./Component/Page/AnalyticalServices.js";
import InLicensing from "./Component/Page/InLicensing.js";
import OutLicensing from "./Component/Page/OutLicensing.js";
import Careers from "./Component/Page/Careers.js";
import ContactUs from "./MainModules/Contact/ContactUs.js";
import ContactUsForm from "./Component/Page/ContactUsForm.js";
import ContactUsCard from "./Component/ContactUsCard.js";
import DunsTradeMark from "./Component/DunsTradeMark.js";
import AvailableForLicensing from "./Component/Page/AvailableForLicensing";
import ProductDevelopment from "./MainModules/ProdDev/ProductDevelopment";
import TechnologyPlatforms from "./MainModules/TechPlat/TechnologyPlatforms";
import OtherProducts from "./MainModules/ProdDev/OtherProducts";
import NutraceuticalProducts from "./MainModules/ProdDev/NutraceuticalProducts";
// import { useEffect } from "react";
const DropdownNav = () => {
  const routes = useRoutes([
    // { IndexRoute component={Home}},
    { path: "/", element: <Home /> },
    { path: "AboutUs", element: <AboutUs /> },
    { path: "ApplyHere", element: <ApplyHere /> },
    { path: "ResearchAndDevlop", element: <ResearchAndDevlop /> },
    { path: "DifferntiatedProdDev", element: <DifferntiatedProdDev /> },
    { path: "Proddev", element: <ProductDevelopment /> },
    { path: "Techplat", element: <TechnologyPlatforms /> },
    { path: "otherproducts", element: <OtherProducts /> },
    { path: "innovativeproducts", element: <InnovativeProdDev /> },
    { path: "otherproduts", element: <InnovativeProdDev /> },
    { path: "ComplexGenerics", element: <ComplexGenerics /> },
    { path: "GenericsProduct", element: <GenericsProduct /> },
    { path: "AvailableForLicensing", element: <AvailableForLicensing /> },
    { path: "OurPresence", element: <OurPresence /> },
    { path: "Pipeline", element: <Pipeline /> },
    { path: "InLicensing", element: <InLicensing /> },
    { path: "OutLicensing", element: <OutLicensing /> },
    { path: "Careers", element: <Careers /> },
    { path: "ContactUs", element: <ContactUs /> },
    { path: "ContactUsForm", element: <ContactUsForm /> },
    { path: "ContactUsCard", element: <ContactUsCard /> },
    { path: "ShareYourIdea", element: <ShareYourIdea /> },
    { path: "PreFormulServices", element: <PreFormulServices /> },
    { path: "FormulDevelopServices", element: <FormulDevelopServices /> },
    { path: "TechnicalServices", element: <TechnicalServices /> },
    { path: "AnalyticalServices", element: <AnalyticalServices /> },
    { path: "DunsTradeMark", element: <DunsTradeMark /> },
    {path: "NutraceuticalProducts",element:<NutraceuticalProducts />},
  ]);

  return routes;
};

function App() {
  return (
    <Router history={createBrowserHistory}>
      <ScrollToTop />
      <Navbar />
      <DropdownNav />
      <FooterRibhus />
    </Router>
  );
}

export default App;
